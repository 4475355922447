
import { defineComponent } from 'vue'
import { Media } from '@/helpers/contentful/interfaces'
import { mapActions } from 'vuex'

export default defineComponent({
    emits: ['media-duration'],
    props: ['medium', 'index', 'currentIndex', 'isPaused'],
    data() {
        return {
            hasError: false,
        }
    },
    methods: {
        ...mapActions(['setMediumAsLoaded']),
        markAsLoaded(medium : Media) {
            this.setMediumAsLoaded(medium)
        },
        markAsFailed() {
            this.hasError = true
            this.setMediumAsLoaded(null)
        }
    },
    computed: {
        isCurrent() : boolean {
            return this.index === this.currentIndex
        }
    }
})
