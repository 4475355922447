export const getInfoscreenMediaQuery  = () : string => {
    return `query getInfoscreenMedia($id: String) {
    infoscreenCollection(where: {id: $id}, limit: 1) {
      items {
        # Näyttö
        isVertical,
        showCampaigns,
        interval,
        playlistsCollection(limit: 30) {
          items {
            # Soittolista
            name,
            itemsCollection(limit: 60) {
              items {
                # Media
                sys {
                    id
                },
                __typename,
                mediaHorizontal {
                  sys {
                    id
                  },
                  contentType,
                  url
                },
                mediaVertical {
                  sys {
                    id
                  },
                  contentType,
                  url
                }
              }
            }
          }
        }
      }
    }
  }`
}

export const getCampaignScreensQuery = () : string => {
    return `query getCampaignScreens {
        campaignCollection(where: { umbrella: true }, limit: 1) {
          items {
            __typename
            name
            infoscreen {
              sys {
                id
              },
              mediaVertical {
                sys {
                  id
                },
                contentType
                url
              }
              mediaHorizontal {
                sys {
                  id
                },
                contentType
                url
              }
            }
            subCampaignsCollection {
              items {
                name
                infoscreen {
                  sys {
                    id
                  },
                  mediaVertical {
                    sys {
                      id
                    },
                    contentType
                    url
                  }
                  mediaHorizontal {
                    sys {
                      id
                    },
                    contentType
                    url
                  }
                }
              }
            }
          }
        }
    }`
}