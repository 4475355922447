import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "data-index"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.hasError)
    ? (_openBlock(), _createElementBlock("video", {
        key: 0,
        ref: _ctx.index + '_' + _ctx.medium.sys.id,
        src: _ctx.medium.url,
        class: _normalizeClass(["slide", { current: _ctx.isCurrent }]),
        "data-index": _ctx.index,
        muted: "true",
        preload: "metadata",
        onLoadedmetadata: _cache[0] || (_cache[0] = ($event: any) => (_ctx.markAsLoaded(_ctx.medium))),
        onError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.markAsFailed()))
      }, null, 42, _hoisted_1))
    : _createCommentVNode("", true)
}