
import { defineComponent } from 'vue'
import Media from './Media.vue'

export default defineComponent({
    name: "ImageMedia",
    extends: Media,
    watch: {
    isCurrent(newVal : boolean) {
      if (newVal) {
        this.$emit('media-duration', -1) // This will set default slide duration
      }
    }
  }
})
