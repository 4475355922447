import { createStore } from 'vuex'
import { getInfoScreenMedia, getCampaignScreens } from '../helpers/contentful/queries'
import { ContentfulInfoscreenData, ContentfulCampaignData, InfoScreen, CampaignScreen, Media } from '../helpers/contentful/interfaces'

/* eslint-disable */
export default createStore({
  state: {
    infoscreen: null as InfoScreen | null,
    campaign: null as CampaignScreen | null,
    loadedMediaCount: 0,
    mediaCount: 0,
    loadedMedia: [] as Media[],
    errorMessage: '',
  },
  actions: {
    async fetchMedia({ commit }, payload) {
      const infoscreen : ContentfulInfoscreenData = await getInfoScreenMedia(payload.infoscreenId)
      if (infoscreen.data) {
        commit('SET_INFOSCREEN', infoscreen.data)
        if (infoscreen.data.showCampaigns) {
          const campaign : ContentfulCampaignData = await getCampaignScreens()
          commit('SET_CAMPAIGN', campaign.data)
        }
      } else {
        commit('SET_INFOSCREEN', null)
        commit('SET_ERROR_MESSAGE', infoscreen.message)
      }
    },
    setMediumAsLoaded({ commit }, payload) {
      if (payload) {
        commit('ADD_TO_LOADED_MEDIA', payload)
      }
      commit('INCREASE_LOADED_MEDIA_COUNT')
    },
    resetMediaLoadedCount({ commit }) {
      commit('RESET_LOADED_MEDIA_COUNT')
    },
    setMediaCount({ commit }, payload) {
      commit('SET_MEDIA_COUNT', payload)
    },
    resetLoadedMedia({ commit }) {
      commit('RESET_LOADED_MEDIA')
    },
    setErrorMessage({ commit }, payload) {
      commit('SET_ERROR_MESSAGE', payload)
    },
  },
  mutations: {
    SET_INFOSCREEN(state, payload) {
      state.infoscreen = payload
    },
    SET_CAMPAIGN(state, payload) {
      state.campaign = payload
    },
    INCREASE_LOADED_MEDIA_COUNT(state) {
      if (state.loadedMediaCount < state.mediaCount) {
        state.loadedMediaCount++
      }
    },
    RESET_LOADED_MEDIA_COUNT(state) {
      state.loadedMediaCount = 0
    },
    SET_MEDIA_COUNT(state, payload) {
      state.mediaCount = payload
    },
    ADD_TO_LOADED_MEDIA(state, payload) {
      if (state.loadedMediaCount < state.mediaCount) {
        state.loadedMedia.push(payload)
      }
    },
    RESET_LOADED_MEDIA(state) {
      state.loadedMedia = []
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload
    },
   },
  getters: {},
  modules: {},
});
