
import { defineComponent } from 'vue'
import { Media, Item, Campaign, PlayList } from '@/helpers/contentful/interfaces'
import ImageMedia from '../components/ImageMedia.vue'
import VideoMedia from '../components/VideoMedia.vue'
import { mapState, mapActions } from 'vuex'
import contentfulConfig from '../config/contentful'

export default defineComponent({
  name: 'Home',
  components: { ImageMedia, VideoMedia },
  data() {
    return {
      infoscreenId: '',
      isVertical: false,
      showDefaultSlide: true,
      message: 'Ladataan mediaa...',
      media: [] as Media[],
      sortedMedia: [] as Media[],
      isContentLoaded: false,
      currentIndex: -1,
      isPaused: false,
      timer: 0,
      interval: 0,
      defaultInterval: 8000,
      defaultScreenTimer: 0,
      status: '',
      statusTimer: 0,
      statusInterval: 2000,
      refreshTimer: 0,
      refreshInterval: contentfulConfig.CONTENTFUL_REFRESH_INTERVAL as number,
    }
  },
  computed: {
    ...mapState([
      'infoscreen',
      'campaign',
      'loadedMediaCount',
      'mediaCount',
      'loadedMedia',
      'errorMessage',
    ]),
    isAllLoaded() : boolean {
      if (this.isContentLoaded) {
        return this.mediaCount === this.loadedMediaCount
      } else {
        return false
      }
    },
  },
  watch: {
    isAllLoaded() {
      if (this.isAllLoaded) {
        this.sortedMedia = this.sortLoadedMedia()
        this.startSlider()
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchMedia',
      'setMediaCount',
      'resetMediaLoadedCount',
      'resetLoadedMedia',
    ]),
    async getMedia() {
      clearInterval(this.refreshTimer)

      // Reset media
      this.media = []
      this.isContentLoaded = false
      this.resetLoadedMedia()
      this.setMediaCount(0)
      this.resetMediaLoadedCount()

      // Get media from Contentful
      await this.fetchMedia({ infoscreenId: this.infoscreenId })

      if (!this.infoscreen) {
        this.message = ''
        this.showDefaultSlide = true
        console.warn(this.errorMessage)
        this.setRefreshTimer(300000) // Try again after 5 minutes
        return false
      }

      const { showCampaigns, playlistsCollection } = this.infoscreen
      this.isVertical = this.infoscreen.isVertical
      this.defaultInterval = this.infoscreen.interval ? (this.infoscreen.interval * 1000) : 8000

      if (showCampaigns && this.campaign !== null) {
        const { infoscreen, subCampaignsCollection } = this.campaign
        // infoscreen.mediaVertical.url = 'https://failing-url.com' // fails to load a media
        this.addMedia(infoscreen)

        subCampaignsCollection?.items?.filter(Boolean).forEach((item: Campaign) => {
          this.addMedia(item.infoscreen)
        })
      }

      playlistsCollection.items.forEach((items: PlayList) => {
        items.itemsCollection.items.forEach((item: Item) => {
          this.addMedia(item)
        })
      })

      this.setMediaCount(this.media.length)
      this.isContentLoaded = true
      console.log('Loaded content:', this.media)
      this.setRefreshTimer()
    },
    addMedia(item : Item) {
      if (!item) {
        return false
      }

      let medium : Media = this.isVertical ? item.mediaVertical : item.mediaHorizontal

      if (medium && medium.url) {
        this.media.push(medium)
      }
    },
    sortLoadedMedia() {
      let sortedResult : Media[] = []
      // If the loadedMedia-array is the same length than media array,
      // all media was loaded and we can use the original media array, no need to do sorting
      if (this.media.length === this.loadedMedia.length) {
        sortedResult = this.media
      } else {
        // If not all media was successfully loaded:
        // Media array has the correct order of slides
        // LoadedMedia-array is not necessarily in correct order
        this.media.forEach((medium : Media) => {
          // Find-function returns the first match
          // In case of multiple matches, we can use the first match multiple times
          const sortedMedium = this.loadedMedia.find((lm : Media) => {
            return medium.url === lm.url
          })
          // If the medium from media-array is found in loadedMedia
          // Add it to sortedResult
          if (sortedMedium) {
            sortedResult.push(sortedMedium)
          }
        });
      }
      return sortedResult
    },
    imageOrVideoComponent(medium : Media) {
        return medium.contentType === 'video/mp4' ? 'video-media' : 'image-media'
    },
    setInterval(seconds : number) {
      const ms = seconds * 1000
      if (ms > this.defaultInterval) {
        this.interval = ms
      } else {
        this.interval = this.defaultInterval
      }
      this.resetPlay()
    },
    startSlider() {
      if (!this.isPaused) {
        this.showDefaultSlide = false

        this.currentIndex = -1
        this.interval = 0
        clearInterval(this.timer)

        this.resetPlay()
      }
    },
    keyEvent(event : KeyboardEvent) {
      if (event.code === 'ArrowRight') {
        clearTimeout(this.statusTimer)
        this.next()
        this.status = 'SKIP >>'
        this.setStatusTimer()
      } else if (event.code === 'ArrowLeft') {
        clearTimeout(this.statusTimer)
        this.prev()
        this.status = '<< BACK'
        this.setStatusTimer()
      } else if (event.code === 'Space') {
        if (this.isPaused) {
          clearTimeout(this.statusTimer)
          this.isPaused = false
          this.resetPlay()
          this.status = 'PLAY |>'
          this.setStatusTimer()
        } else {
          clearTimeout(this.statusTimer)
          this.isPaused = true
          clearInterval(this.timer)
          this.status = 'PAUSE ||'
        }
      } else if (event.code === 'KeyR') {
        this.getMedia()
      }
    },
    next() {
      this.isPaused = false
      this.interval = this.defaultInterval
      this.currentIndex++
      if (this.currentIndex >= this.media.length) {
        this.currentIndex = 0
      }
    },
    prev() {
      this.isPaused = false
      this.interval = this.defaultInterval
      this.currentIndex--
      if (this.currentIndex < 0) {
        this.currentIndex = this.media.length - 1
      }
    },
    resetPlay() {
      clearInterval(this.timer)
      this.play()
    },
    play() {
      this.timer = setInterval(() => {
        this.next()
      }, this.interval)
    },
    setStatusTimer() {
      this.statusTimer = setTimeout(() => {
        this.status = ''
      }, this.statusInterval)
    },
    setRefreshTimer(interval: number|null = null) {
      const refreshInterval = interval || this.refreshInterval;
      this.refreshTimer = setInterval(() => {
        console.log('Refreshing content', new Date().toLocaleString())
        this.getMedia()
      }, refreshInterval)
    },
  },
  mounted() {
    this.infoscreenId = this.$route.params.screenId as string

    if (!this.infoscreenId) {
      this.message = ''
      this.showDefaultSlide = true
      console.warn('Infoscreen group ID missing...')
      return false
    }

    this.getMedia()
    console.log('sorted media: ', this.sortedMedia)
  }
});
