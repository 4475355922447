
import {defineComponent} from 'vue'
import Media from './Media.vue'

export default defineComponent({
  name: 'VideoMedia',
  extends: Media,
  watch: {
    isCurrent(newVal : boolean) {
      const video = this.$refs[this.index + '_' + this.medium.sys.id] as HTMLVideoElement

      if (newVal) {
        if (!video.duration) return;
        this.$emit('media-duration', video.duration + 2) // Add a few extra seconds on video slides
        const playPromise = video.play();
        if (playPromise) {
          playPromise
            .catch((e) => {
              console.log('video loading failure', e)
          });
        }
      } else {
        video.pause()
        video.currentTime = 0
      }
    },
    isPaused(newVal : boolean) {
      const video = this.$refs[this.index + '_' + this.medium.sys.id] as HTMLVideoElement
      if (newVal) {
        video.pause()
      } else {
        video.play()
          .catch(e => {
            console.log('Playing video failed: ', e)
          })
      }
    }
  },
})
