import { getInfoscreenMediaQuery, getCampaignScreensQuery } from './graphql'
import { ContentfulInfoscreenData, ContentfulCampaignData } from './interfaces'
import contentfulConfig from '../../config/contentful'
import axios from 'axios'

const contentfulApi = `${contentfulConfig.CONTENTFUL_GRAPHQL_API_URL}/${contentfulConfig.CONTENTFUL_SPACE_ID}/environments/${process.env.VUE_APP_CONTENTFUL_ENVIRONMENT}`

const axiosHeader = {
    headers: {
        accept: 'application/json ',
        authorization: `Bearer ${contentfulConfig.CONTENTFUL_TOKEN}`,
    },
}

export const getInfoScreenMedia = async (infoscreenId : string) : Promise<ContentfulInfoscreenData> => {
    return await axios.post(contentfulApi, { query: getInfoscreenMediaQuery(), variables: { id: infoscreenId } }, axiosHeader)
        .then((response: any) => {
            const data = response.data.data.infoscreenCollection.items[0]
            let contentfulData : ContentfulInfoscreenData = {
                data
            }
            if (!response.data.data.infoscreenCollection.items[0]) {
                contentfulData = {
                    data: null,
                    message: `No content found for infoscreen ID: ${infoscreenId}. Check that the infoscreen ID is valid.`
                }
            }
            return contentfulData
        })
        .catch((err: any) => {
            console.log('Fetching Infoscreen content from Contentful failed: ', err)
            return {
                data: null,
                message: `Content fetch from Contentul failed: ${err}`
            }
        });
}

export const getCampaignScreens = async () : Promise<ContentfulCampaignData> => {
    return await axios.post(contentfulApi, { query: getCampaignScreensQuery() }, axiosHeader)
    .then((response: any) => {
        const data = response.data.data.campaignCollection.items[0]
        let contentfulData : ContentfulCampaignData = {
            data
        }
        if (!response.data.data.campaignCollection.items[0]) {
            contentfulData = {
                data: null,
                message: 'No content found from Contentful for campaigns'
            }
        }
        return contentfulData
    })
    .catch((err: any) => {
        console.log('Fetching Campaign content from Contentful failed: ', err)
        return {
            data: null,
            message: `Content fetch from Contentul failed: ${err}`
        }
    });
}
